.multiselect__container {
    background-color: #fff;
    border: 1px solid #dfd7ca;
    border-radius: 4px;
    color: #3e3f3a;
    height: 102px;
    margin-bottom: 5px;
    overflow-y: scroll;
    padding: 10px 15px;
}

.multiselect__option {
    padding: 0 2px 1px 2px;
    user-select: none;
}

.multiselect__option--selected {
    background-color: #ddd;
}

.multiselect__bulk-switch {
    cursor: pointer;
    font-size: 14px;
    text-align: right;
    text-decoration: underline;
    text-decoration-style: dotted;
}
