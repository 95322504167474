.dashboardAction {
    display: block;
    margin-bottom: 10px;
}

.dashboardRow {
    padding-bottom: 10px;
}

.smallTableHeading {
    font-weight: bold;
}
