.modalHeader {
    align-items: center;
    color: #2099e6;
    display: flex;
    font-size: 24px;
    flex-direction: row;
}

.modalHeaderText {
    padding-right: 10px;
}
