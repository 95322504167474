.menuSidebar {
    z-index: 500;
}

.mainContainer {
    min-width: 0;
    padding-bottom: 20px;
}

.mainContainer--guest {
    align-items: center;
    background-image: url('/react/images/auth/bg.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mainContainerWrapper {
    flex-grow: 1;
}

.wrapper {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    min-height: 100vh;
    min-width: 100%;
    width: 100%;
}

.authWidgetWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.contentWrapper {
    height: 100%;
}
