.container {
    float: left;
    padding: 0;
    width: 100%;
}

@media (min-width: 768px) {
    .container {
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.summariesContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
}
