.react-tagsinput {
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 6px 12px;
}

.react-tagsinput--focused {
    border-color: #80bdff;
}

.react-tagsinput-tag {
    background-color: #17a2b8;
    border-radius: 4px;
    border: 1px solid #17a2b8;
    color: #fff;
}

.react-tagsinput-tag a {
    color: #fff;
}

.react-tagsinput-tag a:hover {
    text-decoration: none;
}

.react-tagsinput-tag a::before {
    content: " x";
}

.react-tagsinput-input--disabled,
.react-tagsinput--disabled {
    cursor: not-allowed;
}
