.tabsContainer {
    clear: both;
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;
}

.tabHeading {
    background: #6f7e95;
    border-top-right-radius: 4px;
    color: #fff;
    float: left;
    font-size: 14px;
    margin-top: -1px;
    padding: 15px;
    text-align: center;
    width: 100%;
}

.tab {
    background: #fff;
    border-radius: 5px 0 0;
    border-top: 1px solid #ddd;
    color: #444;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    margin: 0 10px 0 0;
    padding: 7px 25px;
    position: relative;
    text-decoration: none;
}

.tab:after {
    background: #fff;
    border-radius: 0 5px 0 0;
    bottom: 0;
    box-shadow: 2px 0 0 rgba(0, 0, 0, 0.4);
    content: "";
    position: absolute;
    right: -8px;
    top: 0;
    transform: skew(20deg);
    width: 16px;
    z-index: 1;
}

.tab--selected {
    background: #6f7e95;
    color: #fff;
}

.tab--selected:after {
    background: #6f7e95;
}
