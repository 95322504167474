.table {
    font-size: 14px;
    margin-bottom: 20px;
}

.cell {
    padding: 2px 10px 2px 0;
}

.nameCell {
    font-weight: 700;
}

.testName:after {
    content: ',';
}

.testStats {
    padding-right: 5px;
}

.testStat {
    padding-right: 5px;
}

.testStats:last-child .testName:after {
    content: '';
}

.statLink {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dotted;
}
