.formWrapper {
    background-color: #ace1f1;
    box-shadow: 0 0 10px #555;
    flex-grow: 1;
    max-width: 530px;
    text-align: center;
}

.logInButton {
    text-transform: uppercase;
}

.logInHeader {
    box-shadow: 0 24px 15px -25px #AAA, 0 8px 15px -15px #555;
    margin-bottom: 40px;
    padding-bottom: 15px;
}

.forgotPasswordLink {
    color: #3e4349;
}

.formStatus {

}
