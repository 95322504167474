.featureImage {
    width: 100%;
}

.readMoreLinkWrapper {
    padding: 20px 0 10px 0;
    text-align: center;
}

.readMoreLink,
.readMoreLink:active,
.readMoreLink:visited,
.readMoreLink:focus,
.readMoreLink:hover {
    color: #f88347;
    font-size: 21px;
    text-decoration: none;
}

.title {
    color: #2099e6;
}
