.switchControl {
    align-items: flex-end;
    display: flex;
    padding: 5px 0;
}

.bottomPanelColumn {
    display: flex;
    justify-content: flex-end;
}

.partnerFlagButtons {
    display: grid;
    gap: 5px;
    grid-template-columns: max-content max-content;
}
