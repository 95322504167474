.panelCell {
    align-items: center;
    display: flex;
    justify-content: center;
}

.panelStack {
    flex-direction: column;
    justify-content: space-around;
}

.panelButton {
    margin-left: 5px;
    width: 46px;
}
