.footer {
  display: flex;
  justify-content: space-between;
}

.actionsContainer {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.actionsContainer>:not(:last-child) {
  margin-right: .25rem;
}

.checkbox {
  padding-left: calc(15px + 1.25rem);
}

.selectAllCheckbox {
  font-weight: 700;
}
