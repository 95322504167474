.formGroups {
    display: flex;
    justify-content: space-between;
}

.input {
    width: auto;
}

.label {
    overflow: hidden;
    padding: 5px 10px;
}

.charsOption {
    cursor: pointer;
    font-size: 14px;
    overflow-x: scroll;
    text-decoration: underline;
    text-decoration-style: dotted;
}
