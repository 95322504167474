.mapContainer {
    border: 1px solid #ddd;
    border-radius: 3px;
    position: relative;
}

.zoomControls {
    display: flex;
    flex-direction: column;
    left: 20px;
    position: absolute;
    top: 20px;
}

.zoomControl:first-child {
    margin-bottom: 10px;
}

.legend {
    bottom: 20px;
    display: flex;
    font-size: 12px;
    height: 30px;
    left: 20px;
    position: absolute;
    width: 20%;
}

.legendScale {
    display: flex;
    height: 50%;
}

.legendLabel {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.legendDynamic {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    margin-right: 10px;
}

.legendStatic {
    display: flex;
    flex-direction: column;
    width: 29px;
}

.legendColorStatic {
    height: 100%;
}
