.container {
    background: linear-gradient(to bottom, #f2f5f7 0%, #e4e8eb 100%);
    border-bottom: 1px solid #c5c8ca;
    height: 66px;
    margin-bottom: 25px;
    padding: 10px 0;
}

.chevronIcon {
    color: #999;
    font-size: 14px;
    margin-right: 10px;
}

.breadcrumbs {
    color: #373d3e;
    display: inline-block;
    font-size: 21px;
    margin: 0;
    padding: 0;
}

.homeLink {
    margin-right: 10px;
}

.homeIcon {
    color: #373d3e;
    font-size: 24px;
}

.navigation {
    align-items: center;
    display: flex;
}

.operator {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.operatorName {
    font-weight: 700;
}

.operatorLabel {
    padding-right: 5px;
}
