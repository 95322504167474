.wrapper {
    background-color: #ace1f1;
    box-shadow: 0 0 10px #555;
    flex-grow: 1;
    max-width: 530px;
    text-align: center;
}

.messageRow {
    padding-bottom: 10px;
}
