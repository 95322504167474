.option {
    padding: 15px 0;
}

.label {
    font-weight: 500;
    padding-left: 10px;
}

.textarea {
    margin-top: 10px;
    height: 300px;
    width: 70%;
}
