.toggleButton,
.toggleButton:hover {
    align-items: center;
    background-color: #fff;
    border-color: #cfcfcf;
    color: #404349;
    display: flex;
    height: 38px;
    justify-content: space-between;
    width: 100%;
}

.chevronIcon {
    font-size: 10px;
}

.searchInput {
    padding: 6px 25px 6px 8px;
    margin: 5px 10px
}

.searchWrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.clearInput {
    color: #777;
    cursor: pointer;
    font-size: 13px;
    font-weight: 300;
    position: absolute;
    right: 20px;
}

.listOverflow {
    max-height: 350px;
    overflow-y: auto;
}
