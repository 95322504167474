.header {
    background: linear-gradient(to bottom, #3a3f41 0%,#1d2021 100%);
    padding: 15px 0;
}

.widget {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.logo {
    height: 57px;
}
