body {
  background-color: #edf2f6;
  color: #6c6c6c;
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 21px;
  margin: 0;
}

a {
  color: #2099e6;
}

a:hover, a:focus {
  color: #3f90e4;
}
