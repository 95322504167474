.subtypeSelectorContainer {
    display: flex;
    justify-content: center;
}

.separatorRow {
    padding-bottom: 15px;
}

.separatorWrapper {
    align-items: center;
    display: flex;
}

.separator {
    background-color: #ddd;
    border: none;
    height: 1px;
    width: 100%;
}
