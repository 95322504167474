.table {
    color: #404349;
    margin: 1px 0 0 0;
    padding: 0;
    width: 100%;
}

.tableCell {
    font-size: 14px;
    line-height: 18px;
    padding: 10px 5px 10px 5px;
    text-align: left;
    white-space: pre-line;
}
