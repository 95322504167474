.fieldsContainer {

}

.dynamicFieldName {
    text-transform: uppercase;
}

.addField {
    align-content: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.addFieldDropdown {
    flex-grow: 1;
    padding-right: 10px;
}

.addFieldButton {
    height: 38px;
    min-height: 38px;
    min-width: 38px;
    width: 38px;
}

.removeField {
    font-size: 20px;
    cursor: pointer;
    font-weight: 700;
}

.dynamicField {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.dynamicFieldInput {
    margin-right: 10px;
    width: auto;
}

.input {
    align-items: center;
    display: flex;
}

.label {
    overflow: hidden;
    padding: 5px 10px;
}
