.useExistingSwitch {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.useExistingSwitchButton:focus {
    box-shadow: none;
}

.formFileLabel {
    white-space: nowrap;
}
