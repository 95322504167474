.tablesContainer {
    display: flex;
    flex-direction: column;
}

.manualCheckTables {
    display: flex;
    flex-direction: row;
}

.manualCheckHeader {
    padding-bottom: 20px;
}

.filteringStats {
    align-items: center;
    display: flex;
    font-weight: 700;
    justify-content: center;
    padding-bottom: 15px;
}
