.flagRow {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
}

.button {
    height: 38px;
    width: 42px;
}

.buttons {
    display: grid;
    gap: 5px;
    grid-template-columns: 1fr 1fr;
}
