.copyrightInfo {
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 14px;
    padding: 15px 0;
}

.copyrightInfo--client {
    background: #d8e3ec;
    color: #6c6c6c;
}

.copyrightInfo--guest {
    background: #262a2b;
    color: #fff;
}
