.roundPicture {
    border-radius: 50%;
    height: 47px;
    margin-right: 15px;
    width: 41px;
}

.userName {
    color: #fff;
    text-decoration: none;
}

.userName:hover {
    color: #149dca;
    text-decoration: underline;
}
